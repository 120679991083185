package com.sieveo.digsig.util

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba

enum class Theme(
    val hex: String,
    val rgb: CSSColorValue
) {
    SuccessGreen(hex = "#00994C", rgb = rgb(r = 0, g = 153, b = 76)),
    FailRed(hex = "#d54641", rgb = rgba(r = 213, g = 70, b = 65, a = 255)),
    Secondary(hex = "#121D34", rgb = rgb(r = 18, g = 29, b = 52)),
    Gray(hex = "#CFCFCF", rgb = rgb(r = 207, g = 207, b = 207)),
    DarkGray(hex = "#acacac", rgb = rgb(r = 172, g = 172, b = 172)),
    DarkGrayTransparent(hex = "#acacac", rgb = rgba(r = 172, g = 172, b = 172, a = 0.5f)),
    LightGray(hex = "#EDEDED", rgb = rgb(r = 237, g = 237, b = 237)),
    LighterGray(hex = "#F9F9F9", rgb = rgb(r = 249, g = 249, b = 249)),
    DigSigBlue(hex = "#052f88", rgb = rgb(r = 5, g = 47, b = 136)),
    LightDigSigBlue(hex = "#3c8cff", rgb = rgb(r = 60, g = 140, b = 255))
}