package com.sieveo.digsig.components

import androidx.compose.runtime.Composable
import com.sieveo.digsig.models.Verification
import com.sieveo.digsig.pages.WarningInfo
import com.sieveo.digsig.util.Consts
import com.sieveo.digsig.util.Theme
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.plus
import org.jetbrains.compose.web.css.px
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi

object DocumentRawData {
    private var documentBytes: List<ByteArray>? = null
    var verification: Verification? = null

    fun clear() {
        documentBytes = null
        verification = null
    }

    fun setDocumentBytes(documentBytes: List<ByteArray>?) {
        this.documentBytes = documentBytes
    }

    fun getDocumentBytes(): List<ByteArray>? {
        return this.documentBytes
    }
}

@OptIn(ExperimentalEncodingApi::class)
@Composable
fun DigSigDocument(breakpoint: Breakpoint) {
    val bottomPadding = Consts.FOOTER_HEIGHT + 20

    val marginLeft = if (breakpoint >= Breakpoint.LG) 140.px + 5.percent
    else if (breakpoint >= Breakpoint.MD) 120.px + 5.percent
    else 5.percent

    Column(
        modifier = Modifier
            .margin(left = marginLeft, right = 5.percent, bottom = bottomPadding.px)
            .fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        WarningInfo(breakpoint)
        DocumentRawData.getDocumentBytes()?.forEach {
            Image(
                src = "data:image/png;base64,${Base64.encode(it)}",
                autoPrefix = false,
                modifier = Modifier
                    .fillMaxWidth()
                    .border(2.px)
                    .borderColor(Theme.DarkGray.rgb)
                    .borderStyle(LineStyle.Solid)
                    .borderRadius(3.px)
            )
        }
    }
}