package com.sieveo.digsig.styles

import com.sieveo.digsig.util.Theme
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.anyLink
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.css.ms

val FooterLinkItemStyle by ComponentStyle {
    base {
        Modifier
            .color(Theme.DarkGray.rgb)
            .transition(CSSTransition(property = "color", duration = 200.ms))
    }
    anyLink {
        Modifier.color(Theme.Gray.rgb)
    }
    hover {
        Modifier.color(Theme.DigSigBlue.rgb)
    }
}