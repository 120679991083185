package com.sieveo.digsig.styles

import com.sieveo.digsig.util.Theme
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.disabled
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px

val ButtonDisableStyle by ComponentStyle {
    base {
        Modifier
            .backgroundColor(Theme.DigSigBlue.rgb)
            .color(Theme.LightGray.rgb)
            .boxShadow(offsetX = 1.px, offsetY = 1.px, color = Colors.Black, blurRadius = 3.px, spreadRadius = 1.px)
            .transition(CSSTransition(property = "backgroundColor", duration = 100.ms))
            .transition(CSSTransition(property = "color", duration = 100.ms))
            .transition(CSSTransition(property = "shadow", duration = 100.ms))
    }

    disabled{
        Modifier
            .boxShadow(offsetX = 0.px, offsetY = 0.px, color = Colors.DarkGray, blurRadius = 3.px,spreadRadius = 1.px)
            .backgroundColor(Theme.LightGray.rgb)
            .color(Theme.DarkGray.rgb)
    }
}
