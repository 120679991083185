package com.sieveo.digsig.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.sieveo.digsig.components.*
import com.sieveo.digsig.util.Consts
import com.sieveo.digsig.util.Consts.CLASSIC_WARNING
import com.sieveo.digsig.util.Consts.SUCCESS_PAGE_TITLE
import com.sieveo.digsig.util.Consts.WARNING
import com.sieveo.digsig.util.Theme
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.functions.opacity
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.fa.FaCircleInfo
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text


@Page("/success")
@Composable
fun SuccessPage() {
    val breakpoint by rememberBreakpoint()

    Box(
        modifier = Modifier
            .fillMaxSize()
            .margin(0.px)
            .position(Position.Fixed)
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .margin(0.px),
            verticalArrangement = Arrangement.SpaceBetween
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth(100.percent),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                Header(SUCCESS_PAGE_TITLE, true, Theme.SuccessGreen.rgb)
                IssuedBy(DocumentRawData.verification?.Organisation ?: "Sieveo GmbH")
            }

            Row(
                modifier = Modifier
                    .fillMaxWidth(100.percent)
                    .height(90.percent)
                    .margin(bottom = 52.px)
                    .overflow(Overflow.Auto),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                if (breakpoint >= Breakpoint.MD) // This hides the side menu in case we have a narrow screen
                    SideMenu(breakpoint)

                if (DocumentRawData.getDocumentBytes()?.isNotEmpty() == true) {
                    DigSigDocument(breakpoint)
                } else {
                    ClassicView(breakpoint)
                }
            }
            Footer()
        }
    }
}

@Composable
fun WarningInfo(breakpoint: Breakpoint, renderDocument: Boolean = true) {
    val fontRatio =
        if (breakpoint >= Breakpoint.XL) 1f else if (breakpoint >= Breakpoint.LG) 0.9f else if (breakpoint >= Breakpoint.MD) 0.8f else if (breakpoint >= Breakpoint.SM) 0.7f else 0.6f
    val fontSize = (22 * fontRatio).px
    val iconSize =
        if (breakpoint >= Breakpoint.LG) IconSize.X2 else if (breakpoint >= Breakpoint.MD) IconSize.X1 else IconSize.LG

    Row(
        modifier = Modifier
            .margin(top = 30.px, bottom = 10.px)
    ) {
        FaCircleInfo(
            modifier = Modifier
                .color(Theme.Secondary.rgb)
                .filter(opacity(50.percent))
                .margin(topBottom = 10.px, leftRight = 3.px),
            size = iconSize
        )

        P(
            attrs = Modifier
                .fontFamily(Consts.FONT_FAMILY)
                .fontSize(fontSize)
                .color(Theme.Secondary.rgb)
                .margin(topBottom = 12.px, leftRight = 3.px)
                .toAttrs()
        ) {
            Text(if (renderDocument) WARNING else CLASSIC_WARNING)
        }
    }
}