package com.sieveo.digsig.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.sieveo.digsig.styles.MenuItemStyle
import com.sieveo.digsig.styles.NavigationItemStyle
import com.sieveo.digsig.util.Consts.FONT_FAMILY
import com.sieveo.digsig.util.Theme
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.functions.invert
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaCheck
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.B
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

object SideMenuContent {
    var digSigInfoContent: Map<String, Boolean>? = null
    var technicalInfoContent: Map<String, String>? = null
    var issuerInfoContent: Map<String, String>? = null

    fun populate(
        digSigInfoContent: Map<String, Boolean>,
        technicalInfoContent: Map<String, String>,
        issuerInfoContent: Map<String, String>
    ) {
        this.digSigInfoContent = digSigInfoContent
        this.technicalInfoContent = technicalInfoContent
        this.issuerInfoContent = issuerInfoContent
    }
}


@Composable
fun SideMenu(breakpoint: Breakpoint) {
    val ctx = rememberPageContext()

    val whoIsOpen = remember { mutableStateOf(-1) }

    if (SideMenuContent.digSigInfoContent == null) {
        ctx.router.navigateTo("/")
    }

    fun openOrClose(pos: Int) {
        if (whoIsOpen.value == pos) {
            whoIsOpen.value = -1
        } else {
            whoIsOpen.value = pos
        }
    }

    val width: CSSNumeric
    val height: CSSNumeric
    val iconSize: CSSNumeric

    when (breakpoint) {
        Breakpoint.ZERO, Breakpoint.SM, Breakpoint.MD -> {
            width = 120.px
            height = 100.px
            iconSize = 30.px
        }

        Breakpoint.LG, Breakpoint.XL -> {
            width = 140.px
            height = 120.px
            iconSize = 38.px
        }
    }

    Column(
        modifier = NavigationItemStyle.toModifier().position(Position.Fixed),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.Start
    ) {
        SideMenuContent(
            title = "DigSig Info",
            iconSrc = "digsiginfo.svg",
            width = width,
            height = height,
            iconSize = iconSize,
            isOpen = whoIsOpen.value == 0,
            open = { openOrClose(0) }
        )
        SideMenuContent(
            title = "Issuer Info",
            iconSrc = "issuerinfo.svg",
            width = width,
            height = height,
            iconSize = iconSize,
            isOpen = whoIsOpen.value == 1,
            open = { openOrClose(1) }
        )
        SideMenuContent(
            title = "Technical Info",
            iconSrc = "technicalinfo.svg",
            width = width,
            height = height,
            iconSize = iconSize,
            isOpen = whoIsOpen.value == 2,
            open = { openOrClose(2) }
        )
//        SideMenuContent(
//            title = "Share",
//            iconSrc = "share.svg",
//        )
    }
}

@Composable
fun SideMenuContent(
    title: String,
    iconSrc: String,
    width: CSSNumeric,
    height: CSSNumeric,
    iconSize: CSSNumeric,
    isOpen: Boolean,
    open: () -> Unit
) {
    Row(
        horizontalArrangement = Arrangement.Start
    ) {
        Column(
            modifier = MenuItemStyle.toModifier()
                .margin(0.px)
                .width(width).height(height)
                .onClick { open() },
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Image(
                src = iconSrc,
                modifier = Modifier
                    .margin(leftRight = 15.px, topBottom = 3.px)
                    .size(iconSize)
                    .filter(invert(100.percent))
            )
            P(
                attrs = Modifier
                    .margin(topBottom = 0.px, leftRight = 15.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(10.px)
                    .lineHeight(1.6)
                    .fontWeight(FontWeight.Normal)
                    .color(Colors.White).textDecorationLine(TextDecorationLine.None).toAttrs(),
            ) {
                Text(title)
            }
        }
        if (isOpen) {
            Box(
                modifier = Modifier
                    .padding(8.px)
                    .color(Colors.Black)
                    .backgroundColor(Theme.DarkGray.rgb)
                    .borderRadius(3.px)
                    .borderStyle(LineStyle.Solid)
                    .borderBottom(1.px)
                    .borderColor(Theme.LighterGray.rgb)
            )
            {
                Column {
                    P(
                        attrs = Modifier
                            .fontSize(16.px)
                            .fontWeight(FontWeight.Bold)
                            .margin(bottom = 5.px)
                            .toAttrs()
                    ) {
                        Text(title)
                    }

                    when (title) {
                        "DigSig Info" -> {
                            SideMenuContent.digSigInfoContent?.entries?.forEach {
                                P(
                                    attrs = Modifier
                                        .fontSize(14.px)
                                        .fontWeight(FontWeight.Normal)
                                        .margin(bottom = 3.px)
                                        .toAttrs()
                                ) {
                                    B {
                                        Text("${it.key}: ")
                                    }
                                    VerificationIcon(it.value)
                                }
                            }
                        }

                        "Issuer Info" -> {
                            SideMenuContent.issuerInfoContent?.entries?.forEach {
                                P(
                                    attrs = Modifier
                                        .fontSize(14.px)
                                        .fontWeight(FontWeight.Normal)
                                        .margin(bottom = 3.px)
                                        .toAttrs()
                                ) {
                                    B {
                                        Text("${it.key}: ")
                                    }
                                    Text(it.value)
                                }
                            }
                        }

                        "Technical Info" -> {
                            SideMenuContent.technicalInfoContent?.entries?.forEach {
                                P(
                                    attrs = Modifier
                                        .fontSize(14.px)
                                        .fontWeight(FontWeight.Normal)
                                        .margin(bottom = 3.px)
                                        .toAttrs()
                                ) {
                                    B {
                                        Text("${it.key}: ")
                                    }
                                    Text(it.value)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@Composable
fun VerificationIcon(verified: Boolean) {
    if (verified) {
        FaCheck()
    } else {
        FaXmark()
    }
}