package com.sieveo.digsig.models

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject

@Serializable
data class Response(
    val Version: String,
    val Verification: Verification,
    val Render: Render
)


