package com.sieveo.digsig.models

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject


@Serializable
data class Verification(
    val DecVer: DecVer,
    val Organisation: String, //"organisation": "Erich Utsch AG",
    val Country: String, //"country": "DE",
    val CommonName: String, //"commonName": "dauri.uvalid.io/cid/109",
    val SigScheme: String, //"sigScheme": "ECBN with SHA256",
    val CertExpiry: String, //"certExpiry": "2060-02-22T23:00:00Z",
    val ValidFrom: String, //"validFrom": "2020-02-22T23:00:00Z"
    val DisplayFields: JsonArray

)


@Serializable
data class DecVer(
    val ResponseCode: ResponseCode,
    val DDDdata: JsonArray,
    val DDDdataTagged: JsonObject,
    val SigData: JsonArray
)

@Serializable
data class Render(val renderedBytes: List<ByteArray>? = null)

@Serializable
data class ResponseCode(val Code: Int, val Desc: String)