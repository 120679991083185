package com.sieveo.digsig

import com.sieveo.digsig.components.ClassicViewDataFields
import com.sieveo.digsig.components.DocumentRawData
import com.sieveo.digsig.components.SideMenuContent
import com.sieveo.digsig.transform.TransformService
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject


class ViewModel() {
    val scope = CoroutineScope(Dispatchers.Main)
    val state = MutableStateFlow(UiState())
    val json = Json { ignoreUnknownKeys = true }

    val mimeType = "text/x-vcard"

    fun doVerify() {
        scope.launch(Dispatchers.Main) {
            state.emit(state.value.copy(inprogress = true, navigateTo = null))

            kotlin.runCatching {

                val response = verify(state.value.digsigInput)
                val verification = response.Verification
                if (verification.DecVer.ResponseCode.Code != 0) throw Exception("DigSig verification failed")

                //TODO Just to test libtransform, delete later
                //val result = TransformService.transform(mimeType, dataMap)
                // Lets create dataMap
                val dataMap =  jsonObjectToMap(response.Verification.DecVer.DDDdataTagged)
                // Decide on whether VCard or not, according to name, company_name, department
                val isVCard = hasRequiredKeys(dataMap)
                if(isVCard){
                    val result = TransformService.transform(mimeType, dataMap)
                }

                val render = response.Render
                DocumentRawData.setDocumentBytes(render.renderedBytes)
                DocumentRawData.verification = verification

                ClassicViewDataFields.extractDisplayFields(verification)
                SideMenuContent.populate(
                    digSigInfoContent = createDigSigInfoContent(verification),
                    issuerInfoContent = createIssuerInfoContent(verification),
                    technicalInfoContent = createTechnicalInfoContent(
                        verification, response.Version
                    )
                )
            }.onSuccess {
                state.emit(state.value.copy(inprogress = false, navigateTo = "/success"))
            }.onFailure {
                println(it.printStackTrace())
                println(it.message)
                println(it.cause)
                state.emit(state.value.copy(inprogress = false, navigateTo = "/fail"))
            }
        }
    }

    fun updateInput(input: String) {
        scope.launch(Dispatchers.Main) {
            state.emit(state.value.copy(digsigInput = input))
        }
    }

    fun clear() {
        state.value = UiState()
        DocumentRawData.clear()
    }

    data class UiState(
        val digsigInput: String = "",
        val inprogress: Boolean = false,
        val errorMessage: String? = null,
        val navigateTo: String? = null
    )
}


fun jsonObjectToMap(jsonObject: JsonObject): Map<String, String> {
    val map = mutableMapOf<String, String>()
    for ((key, value) in jsonObject.entries) {
        map[key] = value.toString()
    }
    return map
}

fun hasRequiredKeys(inputMap: Map<String, String>): Boolean {
    val requiredKeys = setOf("name", "company_name", "department")
    return requiredKeys.all { inputMap.containsKey(it) }
}