package com.sieveo.digsig.pages

import androidx.compose.runtime.Composable
import com.sieveo.digsig.components.*
import com.sieveo.digsig.util.Consts.FONT_FAMILY
import com.sieveo.digsig.util.Theme
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text


@Page("/fail")
@Composable
fun FailPage() {
    Box(
        modifier = Modifier
            .fillMaxSize().margin(0.px)
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .margin(0.px),
            verticalArrangement = Arrangement.SpaceBetween
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth(100.percent),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                Header("Verification Failed", true, Theme.FailRed.rgb)
            }
            Column(
                modifier = Modifier
                    .fillMaxWidth(100.percent)
                    .height(80.percent),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Image(
                    src = "invalidinfo.svg",
                    modifier = Modifier
                        .height(300.px)
                    )
                P(
                    attrs = Modifier
                        .fontFamily(FONT_FAMILY)
                        .fontSize(42.px)
                        .fontWeight(FontWeight.Bold)
                        .textAlign(TextAlign.Center)
                        .toAttrs()
                ){
                    Text("Invalid Info")
                }
                P(
                    attrs = Modifier
                        .fontFamily(FONT_FAMILY)
                        .fontSize(42.px)
                        .fontWeight(FontWeight.Bold)
                        .textAlign(TextAlign.Center)
                        .toAttrs()
                ){
                    Text("No document could be generated")
                }
            }
            Footer()
        }
    }
}