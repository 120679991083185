package com.sieveo.digsig.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.sieveo.digsig.util.Consts.FONT_FAMILY
import com.sieveo.digsig.util.Theme
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun Header(title: String, backButton: Boolean, backgroundColor: CSSColorValue) {
    val breakpoint by rememberBreakpoint()
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(backgroundColor),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        if (backButton) {
            LeftSide()
        }
        RightSide(title, breakpoint)
    }
}

@Composable
fun RightSide(title: String, breakpoint: Breakpoint) {
    val fontRatio =
        if (breakpoint >= Breakpoint.XL) 1f else if (breakpoint >= Breakpoint.LG) 0.85f else if (breakpoint >= Breakpoint.MD) 0.70f else if (breakpoint >= Breakpoint.SM) 0.45f else 0.30f
    Row(
        Modifier
            .fillMaxSize()
            .margin(topBottom = 40.px)
            .fontFamily(FONT_FAMILY)
            .color(Theme.LighterGray.rgb)
            .fontSize((76 * fontRatio).px),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Text(title)
    }
}

@Composable
fun LeftSide() {
    Row(
        Modifier
            .objectFit(ObjectFit.Cover)
            .margin(left = 15.px),
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Link(
            path = "/"
        ) {
            FaArrowLeft(
                modifier = Modifier.color(Theme.LighterGray.rgb),
                size = IconSize.X3
            )
        }

    }
}
