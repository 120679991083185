package com.sieveo.digsig.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.sieveo.digsig.styles.FooterLinkItemStyle
import com.sieveo.digsig.util.Consts
import com.sieveo.digsig.util.Consts.COPYRIGHT
import com.sieveo.digsig.util.Consts.FONT_FAMILY
import com.sieveo.digsig.util.Consts.TECHNOLOGY_BY
import com.sieveo.digsig.util.Consts.version
import com.sieveo.digsig.util.Theme
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun Footer() {
    val breakpoint by rememberBreakpoint()
    Column(
        modifier = Modifier
            .fillMaxWidth(100.percent)
            .margin(top = 20.px, bottom = 0.px, leftRight = 10.px)
//            .verticalAlign(VerticalAlign.Bottom)
            .bottom(0.percent)
            .height(Consts.FOOTER_HEIGHT.px)
            .backgroundColor(Colors.White)
            .flex(1)
            .display(DisplayStyle.Flex)
            .position(Position.Fixed),
        verticalArrangement = Arrangement.Bottom,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(96.percent).margin(bottom = 16.px),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            TechnologyBy(breakpoint)
            Copyright(breakpoint)
            TermsPrivacy(breakpoint)
        }
    }
}

@Composable
fun TechnologyBy(breakpoint: Breakpoint) {
    val ratio =
        if (breakpoint >= Breakpoint.XL) 1f else if (breakpoint >= Breakpoint.LG) 0.85f else if (breakpoint >= Breakpoint.MD) 0.70f else if (breakpoint >= Breakpoint.SM) 0.50f else 0.30f
    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .flex(1)
            .display(DisplayStyle.Flex)
            .justifyContent(JustifyContent.Start)
    ) {
        Label(
            attrs = Modifier
                .fontFamily(FONT_FAMILY)
                .fontSize((16 * ratio).px)
                .color(Theme.Secondary.rgb)
                .textAlign(TextAlign.Start)
                .margin(top = 1.px)
                .minWidth(130 * ratio.px)
                .toAttrs()
        ) {
            Text(TECHNOLOGY_BY)
        }
        Image(src = "sieveologo.svg", modifier = Modifier.width(135 * ratio.px))
        Label(
            attrs = Modifier
                .fontFamily(FONT_FAMILY)
                .fontSize((10 * ratio).px)
                .color(Theme.Secondary.rgb)
                .textAlign(TextAlign.Start)
                .margin(top = 1.px)
                .padding(left = 10.px)
                .toAttrs()
        ) {
            Text("Version: $version")
        }
    }
}

@Composable
fun Copyright(breakpoint: Breakpoint) {
    val ratio = if (breakpoint >= Breakpoint.MD) 1f else if (breakpoint >= Breakpoint.SM) 0.8f else 0.60f
    P(
        attrs = Modifier
            .textAlign(TextAlign.Center)
            .fontFamily(FONT_FAMILY)
            .fontSize((11 * ratio).px)
            .margin(3.px)
            .flex(1)
            .display(DisplayStyle.Flex)
            .justifyContent(JustifyContent.Center)
            .toAttrs()
    ) {
        Text(COPYRIGHT)
    }
}

@Composable
fun TermsPrivacy(breakpoint: Breakpoint) {
    val ratio = if (breakpoint >= Breakpoint.MD) 1f else 0.9f
    Row(
        modifier = Modifier
            .fontFamily(FONT_FAMILY)
            .fontSize((16 * ratio).px)
            .flex(1)
            .display(DisplayStyle.Flex)
            .justifyContent(JustifyContent.End),
        horizontalArrangement = Arrangement.End,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Link(
            path = "",
            text = "Terms",
            modifier = FooterLinkItemStyle.toModifier()
                .padding(leftRight = 10.px)
                .textDecorationLine(TextDecorationLine.None)
        )
        Link(
            path = "",
            text = "Privacy",
            modifier = FooterLinkItemStyle.toModifier()
                .padding(leftRight = 10.px)
                .textDecorationLine(TextDecorationLine.None)
        )
    }
}


