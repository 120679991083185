package com.sieveo.digsig.util

object Consts {
    val version = "0.0.10"
    const val SECTION_WIDTH = 1920
    const val FONT_FAMILY = "Verdana"
    const val WHAT_IS_A_DIGSIG = "A DigSig is a code applied to a document in the form of barcode or NFC to " +
            "authenticate it and ensure it hasn't been tampered with. It functions as a digital \"stamp\" and " +
            "is utilised with a specific blueprint to validate the document's original condition, " +
            "certifying it has remained unaltered since it was stamped."
    //    const val API_URL = "http://localhost:8080/api/v1/render"
    const val API_URL = "https://web-verify-api-ff7eiwwpba-ey.a.run.app/api/v1/render"
    const val CONNECTION_TIMEOUT = 30000L // 30 Second timeout when querying API
    const val FOOTER_HEIGHT = 50
    const val MAIN_PAGE_TITLE = "DigSig Authenticator"
    const val SUCCESS_PAGE_TITLE = "Verification Successful"
    const val COPYRIGHT = "© 2023 Sieveo"
    const val TECHNOLOGY_BY = "Technology by "
    const val WARNING = "Ensure that the generated document below matches the original document"
    const val CLASSIC_WARNING = "Ensure that the information below matches the original document"

    val IGNORE_FIELDS = arrayOf(
        "specificationversion",
        "dauri",
        "daid",
        "cid",
        "signature",
        "timestamp"
    )

}