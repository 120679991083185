package com.sieveo.digsig.components

import androidx.compose.runtime.Composable
import com.sieveo.digsig.components.ClassicViewDataFields.listOfDataFields
import com.sieveo.digsig.models.Verification
import com.sieveo.digsig.pages.WarningInfo
import com.sieveo.digsig.util.Consts
import com.sieveo.digsig.util.Consts.FONT_FAMILY
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import kotlinx.serialization.json.*
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text


object ClassicViewDataFields {
    val listOfDataFields = mutableListOf<Pair<String, MutableList<String>>>()

    fun clearDataFields() {
        listOfDataFields.clear()
    }

    fun extractDisplayFields(verificationObject: Verification) {
        clearDataFields()
        verificationObject.DisplayFields.forEach { currentDisplayField ->
            val fieldId = currentDisplayField.jsonObject["fieldId"]?.jsonPrimitive?.content
            val fieldName = currentDisplayField.jsonObject["fieldName"]?.jsonPrimitive?.content
            val value = currentDisplayField.jsonObject["value"]
            if (!Consts.IGNORE_FIELDS.contains(fieldId)) {
                fieldName?.let {
                    listOfDataFields.addAll(recursiveLabelValue(fieldName, value))
                }
            }
        }
    }

    private fun recursiveLabelValue(
        fieldName: String, displayValue: JsonElement? = null
    ): MutableList<Pair<String, MutableList<String>>> {
        val pairList = mutableListOf<Pair<String, MutableList<String>>>()
        if (displayValue != null) {
            when (displayValue) {
                is JsonPrimitive -> {
                    val p = Pair(fieldName, mutableListOf(displayValue.content))
                    pairList.add(p)
                }

                is JsonObject -> {
                    val fieldName = displayValue.jsonObject["fieldName"]?.jsonPrimitive?.content
                    val value = displayValue.jsonObject["value"]?.jsonPrimitive?.content ?: ""
                    fieldName?.let {
                        pairList.add(Pair(fieldName, mutableListOf(value)))
                    }
                }

                is JsonArray -> {
                    if (displayValue.all { it is JsonObject }) {
                        pairList.add(Pair(fieldName, mutableListOf()))
                    }
                    displayValue.forEach {
                        if (it is JsonPrimitive) {
                            val mainPair = pairList.firstOrNull { pair -> pair.first == fieldName }
                            if(mainPair!=null) {
                                pairList.remove(mainPair)
                                mainPair.second.add(it.jsonPrimitive.content)
                                pairList.add(mainPair)
                            } else {
                                pairList.add(Pair(fieldName, mutableListOf(it.jsonPrimitive.content)))
                            }
                        } else if (it is JsonObject) {
                            val fieldName = it.jsonObject["fieldName"]?.jsonPrimitive?.content
                            val value = it.jsonObject["value"]
                            fieldName?.let {
                                val pList = recursiveLabelValue(fieldName, value)
                                pairList.addAll(pList)
                            }
                        }
                    }
                }
            }
        }
        return pairList
    }
}

@Composable
fun ClassicView(breakpoint: Breakpoint) {

    val dataFieldsInfo = listOfDataFields
    val allInfo = dataFieldsInfo
    val bottomPadding = Consts.FOOTER_HEIGHT + 20

    val marginRatio =
        if (breakpoint >= Breakpoint.XL) 1f else if (breakpoint >= Breakpoint.LG) 0.8f else if (breakpoint >= Breakpoint.MD) 0.6f else if (breakpoint >= Breakpoint.SM) 0.45f else 0.4f
    val fontRatio =
        if (breakpoint >= Breakpoint.XL) 1f else if (breakpoint >= Breakpoint.LG) 0.9f else if (breakpoint >= Breakpoint.MD) 0.8f else if (breakpoint >= Breakpoint.SM) 0.7f else 0.6f
    val leftMargin = (540 * marginRatio).px
    val fontSize = (24 * fontRatio).px

    Column(
        modifier = Modifier
            .margin(left = leftMargin, bottom = bottomPadding.px)
            .padding(bottom = bottomPadding.px)
    ) {
        WarningInfo(breakpoint, renderDocument = false)
        allInfo.forEach {
            Column(horizontalAlignment = Alignment.Start) {
                P(attrs = Modifier.fontWeight(FontWeight.Bold).fontFamily(FONT_FAMILY).fontSize(fontSize).toAttrs()) {
                    Text(it.first)
                }
                P(attrs = Modifier.fontFamily(FONT_FAMILY).fontSize(fontSize).toAttrs()) {
                    it.second.forEach { data -> P { Text("\n$data") } }
                }
            }
        }
    }
}

