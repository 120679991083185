package com.sieveo.digsig

import com.sieveo.digsig.models.RenderRequest
import com.sieveo.digsig.models.Response
import com.sieveo.digsig.models.Verification
import com.sieveo.digsig.util.Consts
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.jsonPrimitive

suspend fun verify(digsigInput: String, dataSnips: Map<String, String>? = null): Response {
    val client = HttpClient() {
        install(ContentNegotiation) {
            json(Json {
                prettyPrint = true
                isLenient = true
            })
        }
    }
    val requestResponse = client.post(Consts.API_URL) {
        contentType(ContentType.Application.Json)
        setBody(
            RenderRequest(
                cidSnip = digsigInput,
                dataSnips = dataSnips
            )
        )
    }
    val result =
        requestResponse.body<Response>()
    client.close()
    return result
}

fun createIssuerInfoContent(verification: Verification): Map<String, String> {

    val dauri = verification.DecVer.DDDdataTagged["dauri"]?.jsonPrimitive?.content.let { it.toString() }
    return mapOf(
        "Domain Authority URL" to dauri,
        "Organisation" to verification.Organisation,
        "Document Valid From" to verification.ValidFrom,
    )
}

fun createTechnicalInfoContent(verification: Verification, apiversion: String): Map<String, String> {

    val daid = verification.DecVer.DDDdataTagged["daid"]?.jsonPrimitive?.content.let { it.toString() }
    val cid = verification.DecVer.DDDdataTagged["cid"]?.jsonPrimitive?.content.let { it.toString() }
    val specVersion =
        verification.DecVer.DDDdataTagged["specificationversion"]?.jsonPrimitive?.content.let { it.toString() }
    val signature = verification.DecVer.DDDdataTagged["signature"]?.jsonPrimitive?.content.let { it.toString() }
    return mapOf(
        "DAID" to daid,
        "CID" to cid,
        "Specification Version" to specVersion,
        "Signature Algorithm" to verification.SigScheme,
        "Signature" to signature,
        "Certificate Expiry" to verification.CertExpiry,
        "API Version" to apiversion
    )
}

fun createDigSigInfoContent(verification: Verification): Map<String, Boolean> {
    return mapOf(
        "Signature Check" to true,
//        "Revocation Check" to true,
        "Online/Offline Verification" to false,
    )
}
