package com.sieveo.digsig.components

import androidx.compose.runtime.Composable
import com.sieveo.digsig.util.Consts.FONT_FAMILY
import com.sieveo.digsig.util.Theme
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun IssuedBy(organisation: String) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(Theme.DigSigBlue.rgb)
            .padding(top = 5.px, bottom = 5.px, left = 15.px )
            .fontFamily(FONT_FAMILY)
            .fontWeight(FontWeight.Medium)
            .color(Theme.LighterGray.rgb),
        contentAlignment = Alignment.CenterStart
    ){
        Text("Issued by: $organisation")
    }
}
