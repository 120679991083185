package com.sieveo.digsig.pages

import androidx.compose.runtime.*
import com.sieveo.digsig.ViewModel
import com.sieveo.digsig.components.Footer
import com.sieveo.digsig.components.Header
import com.sieveo.digsig.styles.ButtonDisableStyle
import com.sieveo.digsig.util.Consts.FONT_FAMILY
import com.sieveo.digsig.util.Consts.MAIN_PAGE_TITLE
import com.sieveo.digsig.util.Consts.WHAT_IS_A_DIGSIG
import com.sieveo.digsig.util.Theme
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text


@Page
@Composable
fun HomePage() {
    val viewModel = remember { ViewModel() }
    val breakPoint by rememberBreakpoint()
    val state = remember { mutableStateOf(viewModel.state.value) }

    val ctx = rememberPageContext()

    LaunchedEffect(true) {
        viewModel.clear()

        if (ctx.params.isNotEmpty()) {
            val cidSnip = ctx.params.keys.first()
            viewModel.updateInput(cidSnip)
            viewModel.doVerify()
        }

        viewModel.state.collect {
            state.value = it
            state.value.navigateTo?.let {
                ctx.router.navigateTo(it)
            }
        }
    }

    Box(
        modifier = Modifier.fillMaxSize().margin(0.px)
    ) {
        Column(
            modifier = Modifier.fillMaxSize().margin(0.px), verticalArrangement = Arrangement.SpaceBetween
        ) {
            Column(
                modifier = Modifier.fillMaxWidth(100.percent),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                Header(MAIN_PAGE_TITLE, false, Theme.DigSigBlue.rgb)
            }
            Column(
                modifier = Modifier.fillMaxWidth(95.percent).height(80.percent).padding(left = 75.px, top = 75.px),
            ) {
                P(
                    attrs = Modifier.fontFamily(FONT_FAMILY).fontSize(36.px).fontWeight(FontWeight.Bold)
                        .textAlign(TextAlign.Left).toAttrs()
                ) {
                    Text("What is DigSig?")
                }
                P(
                    attrs = Modifier.fontFamily(FONT_FAMILY).fontSize(20.px).textAlign(TextAlign.Left).toAttrs()
                ) {
                    Text(WHAT_IS_A_DIGSIG)
                }

                Column(
                    modifier = Modifier.fillMaxWidth(95.percent).margin(top = 40.px),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Input(
                        type = InputType.Text
                    ) {
                        id("inputDigsig")

                        if (state.value.digsigInput.isNotEmpty())
                            defaultValue(state.value.digsigInput)

                        onInput { event ->
                            viewModel.updateInput(event.value) // without updating the state, the <input> will keep showing an old value
                        }
                        style {
                            width(
                                if (breakPoint >= Breakpoint.MD) 800.px
                                else 400.px
                            )
                            height(60.px)
                            borderRadius(10.px)
                            borderColor(Theme.Gray.rgb)
                            borderStyle(LineStyle.Solid)
                        }
                        attr("placeholder", "DigSig Input")
                        attr("name", "digsig")
                        attr("required", "true")
                        attr("required", "true")

                    }
                    Button(attrs = ButtonDisableStyle.toModifier().margin(top = 30.px).height(60.px).width(200.px)
                        .border(width = 0.px).borderRadius(r = 10.px)
//                            .boxShadow(offsetX = 1.px, offsetY = 1.px, color = Colors.Black, blurRadius = 10.px)
                        .cursor(Cursor.Pointer).fontFamily(FONT_FAMILY).fontSize(24.px).toAttrs {
                            onClick {
                                viewModel.doVerify()
                            }
                            if (state.value.inprogress) {
                                disabled()
                            }
                        }

                    ) {
                        if (!state.value.inprogress) {
                            Text("VERIFY")
                        } else {
                            Text("Processing...")
                        }
                    }
                }
            }
            Footer()
        }
    }
}

